<template>
  <footer class="footer colors__background-mystic">
    <img
      src="@/assets/images/footer.svg"
      class="footer__bg"
    >
    <b-container class="px-0">
      <div class="footer__content d-flex justify-content-between">
        <div class="logo d-flex">
          <div class="align-self-center">
            <img src="@/assets/images/logo.svg">
          </div>
          <div class="align-self-center logo__right d-flex flex-column">
            <span class="itraff-typography__logo-title colors__font-shark">iTraff</span>
            <span class="itraff-typography__logo-description colors__font-bali-hai">биржа трафика</span>
          </div>
          <div class="align-self-center" style="padding: 0px 10px; font-size: 90%;">
          ООО "ИНФО МЕДИА" ИНН: 7722338842<br>
          Юридический адрес: 115088, г. Москва, ул. Симоновский Вал, д. 16
          </div>
        </div>
        <span class="align-self-center itraff-typography__body-small colors__font-shark">2020 Digital Leads Все права защищены</span>
      </div>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'ItraffFooter'
}
</script>

<style lang="scss" scoped>
  .footer {
    position: relative;
    height: 96px;
    overflow: hidden;

    &__bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    &__content {
      z-index: 1;
      position: relative;
      height: 100px;

      .logo {
        margin-left: -20px;

        img {
          width: 80px;
          height: 80px;
          margin-top: 12px;
          display: block;
        }

        &__right {
          margin-right: 20px;
        }
      }
    }
  }
</style>
