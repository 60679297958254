import Vue from 'vue'
import App from './App.vue'

//window.addEventListener('message', (e) => window.location.href = e.data)
import { ButtonPlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { LayoutPlugin } from 'bootstrap-vue'
import { FormInputPlugin } from 'bootstrap-vue'
import '@/assets/styles/main.scss';
import '@/assets/styles/bootstrap.scss';

Vue.use(ButtonPlugin)
Vue.use(ModalPlugin)
Vue.use(LayoutPlugin)
Vue.use(FormInputPlugin)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
