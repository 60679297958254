<template>
  <b-modal
    :visible="visible"
    dialog-class="request-modal"
    hide-footer
    @hide="visible = false"
  >
    <template #modal-header-close>
      <svg
        width="22"
        height="23"
        viewBox="0 0 22 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.36707 0.292893C1.97655 -0.0976309 1.34338 -0.0976312 0.952859 0.292893L0.292893 0.95286C-0.0976313 1.34338 -0.0976308 1.97655 0.292893 2.36707L9.01435 11.0885L0.293255 19.8096C-0.0972694 20.2002 -0.0972691 20.8333 0.293255 21.2238L0.952586 21.8832C1.34311 22.2737 1.97628 22.2737 2.3668 21.8832L11.0879 13.1621L19.6205 21.6947C20.011 22.0852 20.6442 22.0852 21.0347 21.6947L21.6947 21.0347C22.0852 20.6442 22.0852 20.011 21.6947 19.6205L13.1621 11.0879L21.6944 2.55559C22.0849 2.16506 22.0849 1.5319 21.6944 1.14137L21.0351 0.482043C20.6445 0.0915184 20.0114 0.0915191 19.6208 0.482043L11.0885 9.01435L2.36707 0.292893Z"
          fill="#ADBCD1"
        />
      </svg>
    </template>
    <img
      src="@/assets/images/modal_bottom.svg"
      class="request-modal__bottom"
    >
    <div class="request-modal__content d-flex flex-column">
      <p class="align-self-center title itraff-typography__title colors__font-shark text-center mb-0">
        Оставить заявку на регистрацию
      </p>
      <b-row class="middle">
        <b-col :cols="5">
          <img
            src="@/assets/images/modal_rocket.svg"
            class="middle__rocket"
          >
        </b-col>
        <b-col :cols="7">
          <div class="h-100 d-flex flex-column justify-content-between">
            <div class="d-flex flex-column">
              <b-form-input
                v-model="name"
                type="text"
                placeholder="Имя"
                class="middle__input"
              />
              <b-form-input
                v-model="surname"
                type="text"
                placeholder="Фамилия"
                class="middle__input"
              />
              <b-form-input
                v-model="email"
                type="text"
                placeholder="E-mail"
                class="middle__input"
              />
            </div>
            <b-button
              variant="success"
              class="w-100"
            >
              Отправить заявку
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p class="help interface-description itraff-typography__body-mini colors__font-shark mb-0">
        После отправки данных с Вами свяжется суппорт и активирует Ваш аккаунт.
        <br><br>
        Мы очень требовательны к рекламодателям, чтобы избежать проблем с запрещенными для
        рекламы тематиками. Владельцам промо-купонов предоставляються особые условия. Мы распространяем купоны на специализированных мероприятиях для вебмастеров и рекламодателей.
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'RequestModal',
  data() {
    return {
      visible: false,
      name: '',
      surname: '',
      email: ''
    }
  },
  mounted() {
    this.$root.$on('openRequestModal', () => {
      this.visible = true
    })
  }
}
</script>

<style lang="scss">
.request-modal {
  max-width: 692px !important;
  height: 522px;

  .modal-content {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,238,243,1) 85%, rgba(235,238,243,1) 100%);
  }

  .modal-header {
    padding: 14px 14px 0 0;
    border-bottom: 0;
  }

  .modal-body {
    padding: 0 74px 50px 74px;
    position: relative;
    overflow: hidden;
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }

  &__content {
    position: relative;
    z-index: 1;

    .title {
      max-width: 296px;
    }

    .middle {
      margin-top: 47px;

      &__rocket {
        width: auto;
        height: 100%;
      }

      &__input {
        margin-bottom: 14px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .help {
      margin-top: 46px;
    }
  }
}
</style>