<template>
  <main class="content">
    <img
      src="@/assets/images/content_circle.svg"
      class="content__circle"
    >
    <img
      src="@/assets/images/content_map.svg"
      class="content__map"
    >
    <b-container class="px-0" fluid>
      <div class="content__content d-flex flex-column">
        <p class="itraff-typography__title colors__font-shark text-center mb-0">
          Большой охват аудитории
        </p>
        <div class="d-flex justify-content-between">
          <div class="left d-flex flex-column">
            <div class="left__item d-flex">
              <img src="@/assets/images/portfolio.svg">
              <p class="text itraff-typography__body-medium colors__font-shark mb-0">
                Наша биржа предоставляет самый большой охват для нужд Вашего бизнеса.
                Это более 90 млн. посетителей со всего СНГ
              </p>
            </div>
            <div class="left__item left__item-central d-flex">
              <img src="@/assets/images/darts.svg">
              <p class="text itraff-typography__body-medium colors__font-shark mb-0">
                Такая аудитория идеальна подходит для ретаргетинга в “промышленных” объемах и позволяет подобрать и конвертировать трафик любому проекту
              </p>
            </div>
            <div class="left__item d-flex">
              <img src="@/assets/images/settings.svg">
              <p class="text itraff-typography__body-medium colors__font-shark mb-0">
                Разработка собственного модуля ретаргетинга, позволит работать с этим инструментом любому рекламодателю без специальных навыков
              </p>
            </div>
            <span class="left__bottom itraff-typography__body-bold colors__font-shark">Все проще чем кажется!</span>
          </div>
          <div class="right d-flex flex-column">
            <div class="right__stats d-flex flex-column">
              <span class="count colors__font-breaker-bay">90 000 000</span>
              <span class="text colors__font-bali-hai">трафика в сутки для арбитража</span>
            </div>
            <b-button
              variant="success"
              class="right__request_button"
              @click="$root.$emit('openRequestModal')"
            >
              Оставить заявку
            </b-button>
          </div>
        </div>
        <p class="interface-title itraff-typography__title colors__font-shark text-center mb-0">
          Простой интерфейс
        </p>
        <p class="interface-description itraff-typography__body-medium colors__font-shark text-center mb-0 text-center">
          Вам нужно просто поставить ссылку, настроить таргетинг по ГЕО и времени. Модерация длится не более часа и сразу после активации на ваш сайт пойдут посетители, которые хорошо конвертируются в продажи. Именно поэтому это трафик хорошо выкупается и конвертится даже у начинающих арбитражников.
          <br><br>
          Попробуйте и Вы, подайте заявку на регистрацию и сотрудник нашей службы поддержки поможет Вам настроить первую рекламную кампанию и подобрать офферы!
        </p>
        <img
          src="@/assets/images/interface.svg"
          class="interface-image"
        >
      </div>
    </b-container>
  </main>
</template>

<script>
export default {
  name: 'ItraffContent'
}
</script>

<style lang="scss" scoped>
  .content {
    height: 100%;
    position: relative;
    padding-top: 138px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,238,243,1) 85%, rgba(235,238,243,1) 100%);

    &__circle {
      position: absolute;
      top: 53px;
      left: 0;
      width: 291px;
      height: 590px;
      z-index: 0;
    }

    &__map {
      position: absolute;
      top: 206px;
      right: 56px;
      width: 603px;
      height: 452px;
      z-index: 1;
    }

    &__content {
      z-index: 2;
      position: relative;

      .left {
        max-width: 550px;
        margin-top: 45px;
        margin-left: 173px;

        &__item {
          margin-bottom: -20px;

          &-central {
            img {
              margin-left: 10px;
            }

            .text {
              margin-left: 0 !important;
            }
          }

          img {
            width: 100px;
            height: 100px;
          }

          .text {
            margin-left: 10px;
            margin-top: 14px;
          }

          &:last-child {
            margin-bottom: 45px;
          }
        }

        &__bottom {
          margin-top: 40px;
          margin-left: 110px;
        }
      }

      .right {
        margin-top: 150px;
        margin-right: 220px;

        &__stats {
          .count {
            font-family: "MontserratBold", sans-serif;
            font-weight: 700;
            font-size: 65px;
            line-height: 30px;
            margin-bottom: 14px;
          }

          .text {
            font-family: "MontserratBold", sans-serif;
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;
            margin-left: 60px;
          }
        }

        &__request_button {
          margin-top: 46px;
          margin-left: 85px;
          width: 200px;
        }
      }

      .interface-title {
        margin-top: 138px;
      }

      .interface-description {
        margin: 45px auto 0px;
        //margin-left: 292px;
        max-width: 828px;
      }

      .interface-image {
        width: 981px;
        height: 610px;
        margin: auto;
      }
    }
  }
</style>
