<template>
  <header class="header">
    <img
      src="@/assets/images/header_strips.svg"
      class="header__strips"
    >
    <img
      src="@/assets/images/header_rocket.svg"
      class="header__rocket"
    >
    <img
      src="@/assets/images/header_bottom.svg"
      class="header__bottom"
    >
    <b-container class="px-0">
      <div class="header__content d-flex flex-column">
        <div class="logo d-flex">
          <img src="@/assets/images/logo.svg">
          <div class="logo__right d-flex flex-column">
            <span class="itraff-typography__logo-title colors__font-shark">iTraff</span>
            <span class="itraff-typography__logo-description colors__font-bali-hai">биржа трафика</span>
          </div>
        </div>
        <div class="main d-flex flex-column">
          <span class="itraff-typography__caption-title colors__font-shark">Биржа трафика №1</span>
          <p class="main__description itraff-typography__caption-description colors__font-bali-hai">
            Номер один в рунете по объемам продаж трафика среди всех аналогичных проектов
            с высококонверсионным трафиком
          </p>
          <b-button
            variant="success"
            class="main__request_button"
            @click="$root.$emit('openRequestModal')"
          >
            Оставить заявку
          </b-button>
        </div>
        <p class="bottom itraff-typography__title colors__font-shark text-center">
          Мы единственный проект, где доля активных
          рекламодателей составляет 70%
        </p>
      </div>
    </b-container>
  </header>
</template>

<script>
export default {
  name: 'ItraffHeader'
}
</script>

<style lang="scss" scoped>
  .header {
    height: 100%;
    position: relative;
    padding-top: 54px;
    padding-bottom: 245px;
    background: linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(235,238,243,1) 85%, rgba(235,238,243,1) 100%);

    &__strips {
      position: absolute;
      top: 0;
      left: 0;
      width: 669px;
      height: 558px;
      z-index: 0;
    }

    &__rocket {
      position: absolute;
      top: 103px;
      right: 77px;
      width: 690px;
      height: 590px;
      z-index: 1;
    }

    &__bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      z-index: 2;
    }

    &__content {
      z-index: 3;
      position: relative;

      .logo {
        margin-left: -20px;

        img {
          width: 100px;
          height: 100px;
        }

        &__right {
          margin-top: 16px;
        }
      }

      .main {
        margin-top: 112px;
        max-width: 500px;

        &__description {
          margin-top: 30px;
        }

        &__request_button {
          margin-top: 55px;
          width: 200px;
        }
      }

      .bottom {
        max-width: 910px;
        margin-top: 176px;
        margin-left: 93px;
      }
    }
  }
</style>
