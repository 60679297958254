<template>
  <div
    id="app"
    class="d-flex flex-column"
  >
    <itraff-header />
    <itraff-content />
    <itraff-footer />
    <request-modal />
  </div>
</template>

<script>
import ItraffHeader from '@/components/Header'
import ItraffContent from "@/components/Content";
import ItraffFooter from "@/components/Footer";
import RequestModal from "@/components/RequestModal";

export default {
  name: 'App',
  components: {
    RequestModal,
    ItraffFooter,
    ItraffContent,
    ItraffHeader
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
